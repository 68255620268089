<template>
  <div class="layout">
    <header>
      <div class="header-left">
        <div class="logo__wrapper" @click="handleRouter">
          <span class="logo-img"
            ><img src="../assets/img/logo-pontosense.png" alt=""
          /></span>
          <span class="system-name"
            >{{ $t("p0001productname") }} | {{ currentServeName }}</span
          >
        </div>
        <div class="nav__wrapper">
          <div
            v-for="(item, index) in navList"
            :key="index"
            :class="[
              activeIndex === index
                ? 'nav__item-active nav__item'
                : 'nav__item',
            ]"
            @click="changeNav(item.path)"
          >
            <span>{{ $t(item.title) }}</span>
            <div class="nav__item-bottom" v-show="activeIndex === index"></div>
          </div>
        </div>
      </div>
      <div class="header-right">
        <div class="tenant-name">{{ orgName }}</div>
        <div class="avatar">{{ avatarText }}</div>
        <div class="user-name">{{ username }}</div>
        <el-dropdown trigger="click" v-if="showSwitch">
          <span class="el-dropdown-link" @click="handleCommand">
            <span class="text">{{ currentHome.name }}</span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="item in homeList"
              :key="item.homeId"
              @click.native="changeHome(item)"
              :class="{ current: currentHome.homeId === item.homeId }"
              >{{ item.name }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>

        <div class="btn-logout" @click="logout">
          <img src="../assets/icon/ico_nav_logout.png" alt="" />
        </div>
      </div>
    </header>
    <upgradeBanner v-if="showBanner"></upgradeBanner>
    <div class="main" :style="{ height: mainHeight }">
      <router-view></router-view>
    </div>
    <div id="toast-box"></div>
    <upgradeMask
      v-if="showUpgrade"
      @upgradeSuccess="showUpgrade = false"
    ></upgradeMask>
  </div>
</template>

<script>
import upgradeBanner from "@/components/upgrade-banner/index.vue";
import upgradeMask from "@/components/upgrade-mask/index.vue";
import { navigationConfig, showNotification } from "@/router/permission";
import store from "@/store";
import { mapMutations } from "vuex";

export default {
  name: "layout",
  components: {
    upgradeBanner,
    upgradeMask,
  },
  data() {
    return {
      // activeIndex: 0,
      isNavigating: false,
      showUpgrade: false,
      showBanner: false,
      socket: null,
      wsUrl:"",
      timer: null,
    };
  },
  mounted() {
    this.getServeList()

    //监听storage变化 动态修改右上角userinfo
    window.addEventListener("storage", (e) => {
      if (e.key === "username" && e.newValue) {
        this.SET_USERNAME(e.newValue);
      }
      if (e.key === "orgName" && e.newValue) {
        this.SET_ORGNAME(e.newValue);
      }
      if (e.key === "currentHome" && e.newValue) {
        this.SET_CURRENTHOME(JSON.parse(e.newValue));
      }
      if (e.key === "role" && e.newValue) {
        this.SET_USERROLE(JSON.parse(e.newValue));
      }
    });
  },
  computed: {
    currentOrigin() {
      return window.origin
    },
    currentServeName() {
      let language = navigator.language.toUpperCase()
      if (language.includes('-')) {
        language = language.split('-')[1]
      }
      if (language === 'ZH') language = 'CN'
      if(language==='CN'){
        return this.$t('p0100china')
      }else{
        return this.$t('p0100canada')
      }
      return store.state.system.serverName;
    },
    showSwitch() {
      return this.roleArr.includes(2) || this.roleArr.includes(3);
    },
    username() {
      if (store.state.user.username) {
        return store.state.user.username;
      } else {
        return "";
      }
    },
    orgName() {
      if (store.state.org.orgName) {
        return store.state.org.orgName;
      } else {
        return "";
      }
    },
    roleArr() {
      if (store.state.user.role) {
        return store.state.user.role;
      } else {
        return "";
      }
    },
    homeList() {
      return store.state.org.homeList;
    },
    currentHome() {
      return store.state.org.currentHome;
    },
    avatarText() {
      if (store.state.user.username) {
        return store.state.user.username.slice(0, 1);
      } else {
        return "";
      }
    },
    navList() {
      let list = [];
      if (this.roleArr) {
        this.roleArr.forEach((item) => {
          list.push(...navigationConfig[item]);
        });
        return list;
      } else {
        return [];
      }
    },
    currentPath() {
      return this.$route.path;
    },
    activeIndex() {
      let pathList = this.navList.map((item) => item.path);
      let currentPath = this.currentPath;
      let index = pathList.findIndex((item) => item === currentPath);

      if (currentPath === "/noaccess") {
        index = "";
      }
      if (currentPath === "/roomdetail") {
        index = pathList.findIndex((item) => item === "/dashboard");
      }
      if (currentPath.includes("/organization")) {
        index = pathList.findIndex((item) => item === "/organization");
      }
      if (currentPath.includes("/activehistory")) {
        index = pathList.findIndex((item) => item === "/activehistory");
      }

      return index;
    },
    roomOccupiedPercent() {
      return ((this.roomOccupoed / this.roomTotal) * 100).toFixed(0);
    },
    showNotification() {
      return showNotification.includes(this.$route.path);
    },
    mainHeight() {
      if (this.showBanner) {
        return `calc(100vh - 118px)`;
      } else {
        return `calc(100vh - 70px)`;
      }
    },
    isUpgrading() {
      return store.state.system.isUpgradting;
    },
    token() {
      return localStorage.getItem("token");
    },
  },
  watch: {
    isUpgrading(newVal) {
      this.showUpgrade = newVal;
      if (!newVal) {
        location.reload();
      }
    },
  },
  methods: {
    getServeList() {
      let language = navigator.language.toUpperCase()
      if (language.includes('-')) {
        language = language.split('-')[1]
      }
      if (language === 'ZH') language = 'CN'//中文zh转为cn 
      this.$http.getServerList(language)
        .then(res => {
          if (res && res?.code === 200) {
            let serverList = res.data
            let currentHost = this.currentOrigin
            if (currentHost.includes('www')) {
              currentHost = currentHost.replace('www.', '')
            }
            //根据当前服务 设置wsUrl
            serverList.forEach(item => {
              if (item.serverUrl === currentHost) {
                localStorage.setItem('wsUrl',item.wsUrl)
                this.wsUrl=item.wsUrl+'/websocket/system/'+this.token
              }
            })
          }
        })
        .then(()=>{
          this.connectWs()
        })
    },
    handleRouter() {
      this.$router.push("/");
    },
    changeNav(path) {
      if (this.$route.path === path) {
        return;
      }
      if (!localStorage.getItem("token")) {
        this.$router.push("/");
      } else {
        if (this.isNavigating) {
          return;
        } else {
          if (this.$route.path.includes(path)) {
            return;
          }

          this.isNavigating = true;
          this.$router.push(path).then(() => {
            this.isNavigating = false;
          });
        }
      }
    },
    changeHome(item) {
      this.$http.setHome(item.homeId).then((res) => {
        if (res && res?.code === 200) {
          localStorage.setItem("currentHome", JSON.stringify(item));
          this.SET_CURRENTHOME(item);
          this.$router.push("/");
        }
      });
    },

    handleCommand() {
      this.getHomeList();
    },
    getHomeList() {
      this.$http.getHomeList().then((res) => {
        if (res && res?.code === 200) {
          localStorage.getItem("homeList", JSON.stringify(res.data));
          this.SET_HOMELIST(res.data);
        }
      });
    },
    logout() {
      this.$http.logout().then((res) => {
        if (res && res.code === 200) {
          localStorage.removeItem("token");
          localStorage.removeItem("orgId");
          localStorage.removeItem("orgName");
          localStorage.removeItem("username");
          localStorage.removeItem("role");
          localStorage.removeItem("homeList");
          localStorage.removeItem("currentHome");
          localStorage.removeItem("belongTo");
          localStorage.removeItem("userId");

          this.SET_USERROLE(null);
          this.SET_USERNAME(null);
          this.SET_USERID(null);

          this.SET_ORGID(null);
          this.SET_ORGNAME(null);
          this.SET_HOMELIST(null);
          this.socket.close();
          this.$router.push("/login");
        }
      });
    },
    handleMsg(message) {
      let data = JSON.parse(message);
      let updateVersion = data.version;
      let updateType = data.updateType;
      let updateTime = data.updateTime;
      let currentVersion = data.currentVersion;

      if (updateType === 0) {
        //预告升级
        if (
          currentVersion !== updateVersion &&
          Number(updateTime) > Number(Math.floor(Date.now() / 1000))
        ) {
          this.showBanner = true;
          this.SET_UPGRADETIME(updateTime);
        }
        return;
      }

      if (updateType === 1) {
        //正在升级
        this.SET_UPGRADETIME(updateTime);
        this.SET_UPGRADTING(true);
        return;
      }
    },
    connectWs() {
      this.socket = new WebSocket(this.wsUrl);
      this.socket.onopen = () => {};
      this.socket.onmessage = (message) => {
        this.handleMsg(message.data);
      };

      this.socket.onclose = (event) => {
        clearTimeout(this.timer);
        this.timer = null;
        this.timer = setTimeout(() => {
          if (!localStorage.getItem("token")) {
            return;
          }
          this.reconnectWs();
        }, 180000);
      };

      this.socket.onerror = (event) => {
        clearTimeout(this.timer);
        this.timer = null;
        this.timer = setTimeout(() => {
          if (!localStorage.getItem("token")) {
            return;
          }
          this.reconnectWs();
        }, 180000);
      };
    },
    reconnectWs() {
      if (this.socket) {
        this.socket.close();
        this.socket = null;
      }
      this.connectWs();
    },

    ...mapMutations([
      "SET_USERROLE",
      "SET_ORGID",
      "SET_USERNAME",
      "SET_USERID",
      "SET_ORGNAME",
      "SET_BANNER",
      "SET_UPGRADTING",
      "SET_UPGRADETIME",
      "SET_HOMELIST",
      "SET_CURRENTHOME",
    ]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_function.scss";

.layout {
  min-width: 1380px;

  height: 100vh;
  min-height: 720px;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #fafafd;

  header {
    display: flex;
    justify-content: space-between;
    min-width: 1380px;

    height: 70px;
    padding: 14px 20px;
    background-color: #fff;
    border: 1px solid rgba(34, 46, 97, 0.06);
    // box-shadow: 0 1px 0 0 rgba(34, 46, 97, 0.06);

    .header-left {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      // background: palegreen;

      > .logo__wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;

        > .logo-img {
          display: flex;
          align-items: center;
          width: 110px;
          height: 24px;
          color: #344ce5;
          font-size: 18px;
          cursor: pointer;

          > img {
            width: 100%;
            height: 100%;
          }
        }

        > .system-name {
          width: 150px;
          display: flex;
          align-items: center;
          font-size: 12px;
          color: #7982a6;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          margin-top: 3px;
        }
      }

      > .nav__wrapper {
        display: flex;
        align-items: center;
        height: 100%;
        margin-left: 80px;

        span {
          padding: 5px 20px;
        }

        span:hover {
          border-radius: 17px;
          background-color: rgba(121, 130, 166, 0.15);
        }

        > .nav__item {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          // font-family: SFPro;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #222e61;
          cursor: pointer;
          margin-right: 40px;
          text-align: center;

          > .nav__item-bottom {
            position: absolute;
            bottom: -5px;
            left: 50%;
            transform: translateX(-50%);
            width: 80%;
            height: 4px;
            background-color: #325bf2;
            border-radius: 10px;
          }
        }

        > .nav__item-active {
          // font-family: SFPro;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #325bf2;
        }
      }
    }

    .header-right {
      display: flex;
      align-items: center;
      height: 100%;

      > .tenant-name {
        // font-family: SFPro;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #222e61;
      }

      > .avatar {
        width: 30px;
        height: 30px;
        background-color: #d9e6fd;
        border-radius: 100%;
        color: #222e61;
        margin: 0 5px 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 600;
      }

      > .user-name {
        color: #222e61;
        font-size: 14px;
        margin-right: 20px;
      }

      ::v-deep .el-dropdown {
        .el-dropdown-link.el-dropdown-selfdefine {
          overflow: hidden;
          color: #222e61;
          font-size: 14px;

          .text {
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
          }

          .el-icon-arrow-down.el-icon--right {
            width: 10px;
            height: 10px;
            background: url("../assets/icon/ic_down.png") no-repeat center;
            background-size: contain;
          }

          .el-icon-arrow-down::before {
            content: "";
          }
        }
      }

      > .btn-logout {
        width: 23px;
        height: 23px;
        cursor: pointer;
        margin-left: 20px;

        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.main {
  min-width: 1380px;
}

#toast-box {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 500px;
  max-height: 100vh;
  overflow-y: auto;
  z-index: 99999;
}
</style>
