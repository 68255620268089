import Vue from "vue";
import VueI18n from "vue-i18n";
import enUS from "./en.json";
import zhCN from "./zh.json";

import codeMap from "./code-map.json";

Vue.use(VueI18n);
const languageList = ["CA", "CN"];

Object.keys(codeMap).forEach((code) => {
  enUS[code] = enUS[codeMap[code]];
  zhCN[code] = zhCN[codeMap[code]];
});

//获取当前language
let language = navigator.language.toUpperCase();
if (language.includes("-")) {
  language = language.split("-")[1];
}

if (language === "ZH") language = "CN"; //中文zh转为cn

export const i18n = new VueI18n({
  locale: languageList.includes(language) ? language : "CA", // 语言标识
  messages: {
    CA: enUS,
    CN: zhCN,
  },
});

export function translate(key) {
  return i18n.t(key, i18n.messages[i18n.locale]);
}
