import { SET_UPGRADTING,SET_UPGRADETIME,SET_TOASTCOUNT,SET_SERVERNAME,SET_WSURL} from "../mutations";
import mutations from "../mutations";

const state = {
  isUpgradting: false,
  upgradeTime:"",
  toastCount:0,
  serverName:"Canada",
  wsUrl:localStorage.getItem('wsUrl')||''
};

const actions = {

  setUpgradting({ commit }, isUpgradting) {
    commit(SET_UPGRADTING, isUpgradting);
  },
  setUpgradeTime({ commit },upgradeTime ) {
    commit(SET_UPGRADETIME, upgradeTime);
  },
  setToastCount({ commit },toastCount ) {
    commit(SET_TOASTCOUNT, toastCount);
  },
  setServerName({commit},serverName){
    commit(SET_SERVERNAME,serverName);
  },
  setWsUrl({commit},wsUrl){
    commit(SET_WSURL,wsUrl);
  },

};

const getters = {
  getUpgradting: (state) => state.isUpgradting,
  getUpgradeTime: (state) => state.upgradeTime,
  getToastCount: (state) => state.toastCount,
  getServerName: (state) => state.serverName,
  getWsUrl: (state) => state.wsUrl,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
