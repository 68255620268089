import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import VueI18n from "vue-i18n";
import { i18n } from "./locales";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import enLocale from "element-ui/lib/locale/lang/en"; // 导入英文语言包
import cnLocale from "element-ui/lib/locale/lang/zh-CN"; // 导入英文语言包
import Http from "./services";
import toast from "@/components/toast/index";
import vGanttChart from "v-gantt-chart";
Vue.use(vGanttChart);

//获取当前language
let language = navigator.language.toUpperCase();
if (language.includes("-")) {
  language = language.split("-")[1];
}

if (language === "ZH") language = "CN"; //中文zh转为cn

if (language === "CN") {
  Vue.use(ElementUI, { locale: cnLocale });
} else {
  Vue.use(ElementUI, { locale: enLocale });
}

Vue.use(Http);

Vue.config.productionTip = false;

Vue.prototype.$toast = toast;

new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
