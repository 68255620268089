// user
export const SET_USERROLE = "SET_USERROLE";
export const SET_USERNAME = "SET_USERNAME";
export const SET_USERID = "SET_USERID";

// system
export const SET_UPGRADTING = "SET_UPGRADTING";
export const SET_UPGRADETIME = "SET_UPGRADETIME";
export const SET_TOASTCOUNT = "SET_TOASTCOUNT";
export const SET_SERVERNAME="SET_SERVERNAME";
export const SET_WSURL="SET_WSURL"

// org
export const SET_ORGID = "SET_ORGID";
export const SET_ORGNAME = "SET_ORGNAME";
export const SET_HOMELIST = "SET_HOMELIST";
export const SET_CURRENTHOME = "SET_CURRENTHOME";
export const SET_TREEID = "SET_TREEID";
export const SET_TREENAME = "SET_TREENAME";

export const SET_TREE_LEVEL = "SET_TREE_LEVEL";

const mutations = {
  // user
  [SET_USERROLE](state, role) {
    state.role = role;
  },
  [SET_USERNAME](state, username) {
    state.username = username;
  },
  [SET_USERID](state, userId) {
    state.userId = userId;
  },
  // system
  [SET_UPGRADTING](state, isUpgradting) {
    state.isUpgradting = isUpgradting;
  },
  [SET_UPGRADETIME](state, upgradeTime) {
    state.upgradeTime = upgradeTime;
  },
  [SET_TOASTCOUNT](state, toastCount) {
    state.toastCount = toastCount;
  },
  [SET_SERVERNAME](state,serverName){
    state.serverName=serverName;
  },
  [SET_WSURL](state,wsUrl){
    state.wsUrl=wsUrl
  },

  // org
  [SET_ORGID](state, orgId) {
    state.orgId = orgId;
  },

  [SET_ORGNAME](state, orgName) {
    state.orgName = orgName;
  },
  [SET_TREEID](state, treeId) {
    state.treeId = treeId;
  },
  [SET_TREENAME](state, treeName) {
    state.treeName = treeName;
  },
  [SET_HOMELIST](state, homeList) {
    state.homeList = homeList;
  },
  [SET_CURRENTHOME](state, currentHome) {
    state.currentHome = currentHome;
  },
  [SET_TREE_LEVEL](state, treeLevel) {
    state.treeLevel = treeLevel;
  },
};

export default mutations;
